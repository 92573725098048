html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

.dimmed-icon {
    opacity: 0.25;
}

@counter-style easa-list-first {
    system: extends lower-alpha;
    prefix: '(';
    suffix: ') ';
}

@counter-style easa-list-second {
    system: extends decimal;
    prefix: '(';
    suffix: ') ';
}

@counter-style easa-list-third {
    system: extends lower-roman;
    prefix: '(';
    suffix: ') ';
}

@counter-style easa-list-fourth {
    system: extends upper-alpha;
    prefix: '(';
    suffix: ') ';
}

.easa ol {
    list-style-type: easa-list-first;
}

.easa ol ol {
    list-style-type: easa-list-second;
}

.easa ol ol ol {
    list-style-type: easa-list-third;
}

.easa ol ol ol ol {
    list-style-type: easa-list-fourth;
}

.easa li {
    padding-top: 8px;
}
